import React from "react"
import { Link } from "gatsby"
import Image from "../components/image"
import SEO from "../components/seo"
import one from "../images/01.svg"
import two from "../images/02.svg"
import { defaultStaticContext } from '../context/StaticContext'

const pre_launch = (+ new Date(defaultStaticContext.startDate) - + new Date()) <= 0 || defaultStaticContext.mode !== 'pre-launch';

const ProductPage = () => (
  <div className="productPage">
    <SEO title="Product customize" />
    <div className="productPage__container">
      <a
      className="productPage__link"
      href={pre_launch ? "/sacoche" : "/signup"}>
        {/* <div className="productPage__color left" />
        <div className="productPage__color right" />
        <div className="productPage__color righttop" /> */}
        {/* <Image
          filename="sacoche__bg1.png"
          classname="productPage__image--bg"
        /> */}
        <Image
          filename="bag0__m.jpg"
          classname="productPage__image mobile"
          style={{
            position: 'absolute'
          }}
        />
        <Image
          filename="bag1.jpg"
          classname="productPage__image desktop"
          style={{
            position: 'absolute'
          }}
        />
      </a>
      <a
      className="productPage__link"
      href={pre_launch > 0 ? "/quick-pocket" : "/signup"}>
        {/* <div className="productPage__color right" />
        <div className="productPage__color left" />
        <div className="productPage__color leftbottom" />         */}
        {/* <Image
          filename="quick-pocket__bg1.png"
          classname="productPage__image--bg"
        /> */}
        <Image
          filename="bag1__m.jpg"
          classname="productPage__image mobile"
          style={{
            position: 'absolute'
          }}
        />
        <Image
          filename="bag0.jpg"
          classname="productPage__image desktop"
          style={{
            position: 'absolute'
          }}
        />
      </a>
    </div>
  </div>
)

export default ProductPage
